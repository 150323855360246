import React, { useContext } from 'react'
import { Col, Container, Row, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { GiFruitBowl } from 'react-icons/gi'
import { AuthContext } from '../../context/AuthContext'
import { Redirect } from 'react-router-dom'

const Home = () => {
    const iconSize = document.documentElement.clientHeight * 0.65;
    const { valid, loggedIn } = useContext(AuthContext);

    // Avoid rendering until auth state is retrieved
    if (!valid) return <div/>

    // Redirect to records page if logged in
    else if (valid && loggedIn) return <Redirect to='/records' />

    return (
    <Container style={{
        display: "flex",
        alignItems: "center",
        height: '100vh'
    }}>
    <Col sm='12' md={{ size: 4, offset: 2 }}>
        <Row><h1 className='display-3'>Orchard</h1><br/></Row>
        <Row><p className='lead'>Attendance records and analytics.</p></Row>
        <Row>
            <Button
                outline
                color='primary'
                size='lg'
                tag={Link} 
                to='/login'>
                    Log In
            </Button>
        </Row>
    </Col>
    <Col sm='12' md={{ size: 4 }}>
        <GiFruitBowl size={iconSize}/>
    </Col>
    </Container>
    )
}

export default Home;