export const addressPoints = [
    [34.194489, -118.138263, 0.23, 0.4223776223776224],
    [34.136258, -118.026704, 0.2, 0.12167832167832168],
    [34.280333, -117.866141, 0.39, 0.42097902097902096],
    [34.09741, -117.968269, 0.41, 0.427972027972028],
    [33.970343, -118.171368, 0.69, 0.6615384615384615],
    [33.887821, -118.12725, 0.41, 0.544055944055944],
    [34.064958, -118.382979, 0.16, 0.5174825174825175],
    [34.2044, -118.287527, 0.17, 0.4797202797202797],
    [34.123305, -118.671025, 0.13, 0.29790209790209793],
    [33.867743, -118.069472, 0.09, 0.0],
    [34.128066, -117.714773, 0.12, 0.0],
    [34.084747, -117.886844, 0.31, 0.4489510489510489],
    [33.997862, -118.393617, 0.14, 0.4727272727272727],
    [33.98627, -117.821282, 0.16, 0.13286713286713286],
    [33.955729, -118.118346, 0.36, 0.6083916083916084],
    [34.023728, -118.156275, 0.66, 0.7580419580419581],
    [34.078573, -118.043456, 0.53, 0.0],
    [33.890853, -118.297967, 0.28, 0.5146853146853146],
    [34.153338, -118.262974, 0.29, 0.6],
    [34.156529, -117.841605, 0.15, 0.42377622377622376],
    [33.999005, -117.972146, 0.21, 0.27412587412587414],
    [33.914775, -118.348083, 0.46, 0.27692307692307694],
    [33.979248, -118.217438, 0.73, 0.7986013986013986],
    [33.95889, -118.330548, 0.39, 0.6461538461538462],
    [34.031415, -117.93647, 0.38, 0.2951048951048951],
    [34.18001, -117.773939, 0.14, 0.2055944055944056],
    [33.841027, -118.079141, 0.16, 0.0],
    [33.88856, -118.351813, 0.34, 0.4111888111888112],
    [33.889151, -118.402127, 0.07, 0.2951048951048951],
    [33.987729, -118.186597, 0.72, 0.8517482517482518],
    [34.1528, -118.000482, 0.24, 0.6125874125874126],
    [34.015444, -118.111012, 0.41, 0.6657342657342658],
    [34.050965, -118.114394, 0.41, 0.28251748251748254],
    [33.906956, -118.08264, 0.42, 0.42937062937062936],
    [34.59448, -117.813666, 0.48, 0.6643356643356644],
    [33.898883, -118.166629, 0.55, 0.6055944055944056],
    [33.989524, -118.089299, 0.38, 0.8433566433566434],
    [34.083086, -117.737997, 0.52, 0.07132867132867132],
    [33.753155, -118.367963, 0.08, 0.27132867132867133],
    [33.830006, -118.387124, 0.1, 0.26853146853146853],
    [34.065493, -118.084529, 0.47, 0.18321678321678322],
    [33.974883, -117.900676, 0.31, 0.3006993006993007],
    [34.110186, -117.81039, 0.13, 0.16503496503496504],
    [34.286687, -118.43508, 0.51, 1.0],
    [34.090776, -118.095091, 0.33, 0.3104895104895105],
    [34.034666, -118.504039, 0.27, 0.3706293706293706],
    [33.944159, -118.192761, 0.51, 0.6265734265734266],
    [34.108957, -118.156615, 0.16, 0.6377622377622377],
    [34.102077, -118.057754, 0.22, 0.5370629370629371],
    [33.867257, -118.330794, 0.13, 0.32167832167832167],
    [34.018195, -117.854821, 0.15, 0.15944055944055943],
    [34.022581, -117.902359, 0.26, 0.3384615384615385],
    [34.072924, -118.37271, 0.28, 0.627972027972028],
    [34.00784, -118.030271, 0.33, 0.4433566433566434]
]