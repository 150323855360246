import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Table,
    FormGroup, Input, Row, Col, Spinner } from 'reactstrap';
import firebase from '../../firebase';

const EditServices = (props) => {

    const [services, setServices] = useState([]);
    const [newService, setNewService] = useState("");
    const [newServiceLoading, setNewServiceLoading] = useState(false);
    const [rmServiceLoading, setRmServiceLoading] = useState("");

    useEffect(() => {
        if (props.location) {
            setServices(props.location.services);
        }
    }, [props.location])

    function closeModal() {
        props.toggle();
        setNewService("");
    }

    async function removeService(e) {
        const toRemove = e.target.parentElement.parentElement.id;
        try {
            const newServices = [...services].filter(elem => elem !== toRemove);
            setRmServiceLoading(toRemove);
            await firebase.firestore()
                .collection('locations')
                .doc(props.location.id)
                .update({
                    services: newServices
                });
            setServices(newServices);
            props.location['services'] = newServices;
        } catch (e) {
            console.log(e);
        } finally {
            setRmServiceLoading("");
        }
    }

    async function addService() {
        if (newService === "") return;
        try {
            const newServices = [...services, newService];
            setNewServiceLoading(true);
            await firebase.firestore()
                .collection('locations')
                .doc(props.location.id)
                .update({
                    services: newServices
                });
            setServices(newServices);
            setNewService("");
            props.location['services'] = newServices;
        } catch (e) {
            console.log(e);
        } finally {
            setNewServiceLoading(false);
        }
    }

    return (
        <Modal isOpen={props.active} toggle={closeModal}>
            <ModalHeader toggle={closeModal}>Services</ModalHeader>
            <ModalBody>
            <Table striped>
                <tbody>
                { services.map(service => 
                <tr id={service} key={service}>
                    <td>{service}</td>
                    <td className='text-center'>
                    { rmServiceLoading === service ? <Spinner color='danger' /> :
                        <Button
                            size='sm'
                            block
                            outline
                            color='danger'
                            onClick={removeService}
                        >Remove</Button>
                    }
                    </td>
                </tr>
                )}
                </tbody>
            </Table>
            <Row>
                <Col md='8'>
                <FormGroup>
                    <Input 
                        type="text"
                        name="newService"
                        id="newService"
                        placeholder="New Service"
                        value={newService}
                        onChange={e => setNewService(e.target.value)}
                    />
                </FormGroup>
                </Col>
                <Col className='text-center' md='4'>
                { newServiceLoading ? <Spinner color='success' /> :
                    <Button
                        block
                        outline
                        color='success'
                        onClick={addService}
                    >Add</Button>
                }
                </Col>
            </Row>
            </ModalBody>
        </Modal>
    )
}

export default EditServices;