import React, { useState } from 'react';
import { TabPane, Button, Spinner } from 'reactstrap';
import { triggerPredict } from '../../helpers/AdminUtils';
import PredictionTable from './PredictionTable';
import { firebaseFromToday } from '../../helpers/DateUtils';

const CurrentPredictions = (props) => {

    const [ loading, setLoading ] = useState(false);

    async function handleClick() {
        setLoading(true);
        await triggerPredict(props.location.id);
        await props.refreshData()
        setLoading(false);
    }

    if (!props.active) return <div/>

    // Disable refresh if we already ran model today
    let disableRefresh = false;
    if (props.predictions && props.predictions.length > 0) {
        disableRefresh = firebaseFromToday(
            props.predictions[props.predictions.length - 1].created
        );
    }

    if (loading) {
        return (
            <div className='text-center'>
                <Spinner 
                    color='primary'
                    style={{ 
                        width: '3rem',
                        height: '3rem',
                        marginTop: '3rem',
                        marginBottom: '2rem'
                    }}
                />
                <h3 className='lead'>This might take a few minutes...</h3>
            </div>
        )
    }

    return (
        <TabPane tabId={props.tabId}>
            { props.predictions && props.predictions.length > 0 ?
                <PredictionTable
                    services={props.services}
                    predictions={props.predictions}
                /> : <br/>
            }
            <br/>
            <div className='text-center'>
                <Button
                    color='primary'
                    outline
                    onClick={handleClick}
                    style={{width: '50%'}}
                    disabled={ disableRefresh }
                >Update Predictions</Button>
            </div>
            <br/><br/>
        </TabPane>
    )
}

export default CurrentPredictions;