import React from 'react'
import Home from './components/home/Home'
import Login from './components/home/Login'
import AuthContextProvider from './context/AuthContext'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Records from './components/user/Records'
import LocalStats from './components/stats/local/LocalStats'
import Settings from './components/settings/Settings'
import MapContainer from './components/map/MapContainer'
import Predict from './components/predict/Predict'

function App() {
  return (
    <AuthContextProvider>
    <BrowserRouter>
     <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/login' component={Login} />
        <Route path='/records' component={Records} />
        <Route path='/stats' component={LocalStats} />
        <Route path='/settings' component={Settings} />
        <Route path='/map' component={MapContainer} />
        <Route path='/predict' component={Predict} />
     </Switch>
    </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
