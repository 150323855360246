import React, { Component } from 'react';
import { Button, Table, TabPane, Spinner } from 'reactstrap';
import firebase from '../../firebase';
import EditServices from './EditServices';
import NewLocation from './NewLocation';
import ConfirmDelete from './ConfirmDelete';
import { getRecordsForExport } from '../../helpers/StoreUtils';
import { firebaseFromToday } from '../../helpers/DateUtils';
import { ExportToCsv } from 'export-to-csv';

class Locations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            selectedLocation: null,
            deleteLocation: null,
            serviceModal: false,
            locationModal: false,
            deleteModal: false,
            deleteLoading: "",
            exportLoading: ""
        }
        this.setDeleteModal = this.setDeleteModal.bind(this);
        this.removeLocation = this.removeLocation.bind(this);
        this.exportData = this.exportData.bind(this);
    }
    
    async componentDidMount() {
        try {
            // Get user organization
            const user = firebase.auth().currentUser;
            const token = await user.getIdTokenResult();
            const organization = token.claims.organization;

            // Get all locations in the organization
            const locationDocs = await firebase.firestore()
                .collection('locations')
                .where('organization', '==', organization)
                .get();
            let locations = []
            locationDocs.forEach(doc => {
                let docData = doc.data();
                docData['id'] = doc.id;
                locations.push(docData);
            })
            this.setState({ locations });
        } catch (e) {
            console.log(e);
        }
    }

    formatArray = (array, cutoff) => {
        // Add all elements to output
        let formatted = "";
        for (let elem of array) {
            formatted += elem.toString() + ', ';
        }
        if (formatted.length === 0) return "";

        // Trim last 2 characters, then to length
        formatted = formatted.slice(0, -2);
        return formatted.length < cutoff ? formatted : 
            formatted.substr(0, cutoff-1) + '...';
    }

    setDeleteModal = (location) => {
        this.setState({
            deleteLocation: location,
            deleteMsg: location.name,
            deleteModal: true
        })
    }

    removeLocation = async () => {
        try {
            this.setState({deleteLoading: this.state.deleteLocation.name})
            await firebase.firestore()
                .collection('locations')
                .doc(this.state.deleteLocation.id)
                .delete();
            let newLocations = [...this.state.locations]
                .filter(loc => loc !== this.state.deleteLocation)
            this.setState({
                locations: newLocations,
                deleteLocation: null,
                deleteMsg: ""
            })
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({deleteLoading: ""})
        }
    }

    exportData = async (location) => {
        this.setState({ exportLoading: location.name })
        const records = await getRecordsForExport(location.id, location.services);
        const options = { 
            fieldSeparator: ',',
            decimalSeparator: '.',
            showLabels: true,
            filename: location.name,
            useTextFile: false,
            useBom: true,
            headers: ['date', ...location.services]
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(records);
        this.setState({ exportLoading: "" })
    }

    closeServiceModal = () => { this.setState({ serviceModal: false }) }
    closeLocationModal = () => { this.setState({ locationModal: false }) }
    closeDeleteModal = () => { this.setState({ deleteModal: false }) }

    render() {
        return (
        <TabPane tabId={this.props.tabId}>
            <div className='text-right'>
                <Button
                    outline
                    size="sm"
                    color='success'
                    style={{marginBottom: 10}}
                    onClick={() => this.setState({locationModal: true})}
                >New Location</Button>
            </div>
            <Table striped>
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Services</th>
                        <th/>
                        <th/>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                { this.state.locations.map(location => 
                    <tr id={location.name} key={location.name}>
                        <td>{location.name}</td>
                        <td>{this.formatArray(location.services, 40)}</td>
                        
                        <td className='text-center'>
                        { this.state.exportLoading === location.name ?
                            <Spinner color='primary' /> :
                            <Button
                                size='sm'
                                outline
                                color='primary'
                                disabled={
                                    'exported' in location && 
                                    firebaseFromToday(location.exported)
                                }
                                onClick={() => this.exportData(location)}
                            >Export Data</Button>
                        }
                        </td>

                        <td className='text-center'>
                            <Button
                                size='sm'
                                outline
                                color='primary'
                                onClick={() => this.setState({
                                    serviceModal: true,
                                    selectedLocation: location
                                })}
                            >Edit Services</Button>
                        </td>

                        <td className='text-center'>
                        { this.state.deleteLoading === location.name ? 
                            <Spinner color='danger' /> :
                            <Button
                                size='sm'
                                outline
                                color='danger'
                                onClick={() => this.setDeleteModal(location)}
                            >Remove Location</Button>
                        }
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
            <EditServices 
                active={this.state.serviceModal}
                location={this.state.selectedLocation}
                toggle={this.closeServiceModal}
            />
            <NewLocation
                active={this.state.locationModal}
                toggle={this.closeLocationModal}
                locations={this.state.locations}
            />
            <ConfirmDelete
                active={this.state.deleteModal}
                name={this.state.deleteMsg}
                rmFunction={this.removeLocation}
                toggle={this.closeDeleteModal}
            />
        </TabPane>
        )
    }
}

export default Locations;