 /* global google */
 import React, { Component } from 'react'
 import GoogleMapReact from 'google-map-react'
 import { addressPoints } from './data';
 
 class MapDetail extends Component {

  static defaultProps = {
    center: {
      lat: 34.0522,
      lng: -118.2437
    },
    zoom: 11
  }

  constructor(props) {
    super(props)
    this.state = {
      heatmapVisible: true,
      heatmapPoints: [],
      covidWeight: 0
    };
    this.setPointValues = this.setPointValues.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.layers !== nextProps.layers) {
        this.setPointValues(nextProps.layers)
    }
    if (this.props.radius !== nextProps.radius) {
      this._googleMap.heatmap.set('radius', this._googleMap.heatmap.get('radius') ? null : nextProps.radius);
    }
    return true;
  }

  setPointValues(layers) {
    // Calculate new point values
    let points = [];
    if (layers.length !== 0) {
      for (let row of addressPoints) {
        let point = {};
        point['lat'] = row[0];
        point['lng'] = row[1];

        point['weight'] = 0;
        if (layers.includes('poverty')) {
          point['weight'] += row[2];
        }
        if (layers.includes('covid')) {
          point['weight'] += row[3]
        }
        point['weight'] /= layers.length;
        points.push(point);
      }
    }

    // Add to state
    this.setState({ heatmapPoints: points })
    if (this._googleMap !== undefined) {
      this._googleMap.heatmap.data = points;
    }
  }
 
  render() {
    const apiKey = {key: 'AIzaSyA1I2s3iTCjXQoyCw1LLEj2Rb4w0N4YyLk'}
    const heatMapData = {
      positions: this.state.heatmapPoints,
      options: {
        radius: 15,
        opacity: 0.6
      }
    }

    return (
      <div style={{ height: '80vh', width: '100%' }}>
        <GoogleMapReact
          ref={(el) => this._googleMap = el}
          bootstrapURLKeys={apiKey}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          heatmapLibrary={true}
          heatmap={heatMapData}
        />
      </div>
    )
  }
 }
 
 export default MapDetail