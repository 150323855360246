import React, { Component } from 'react';
import { Button, FormGroup, Label, Input, Alert, Row, Col, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { createUser } from '../../helpers/AdminUtils';

class NewUser extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password1: "",
            password2: "",
            loading: false,
            errorMsg: "",
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    async handleSubmit(e) {

        // Enter loading state
        this.setState({
            loading: true,
            errorMsg: "",
        });

        // Call new_user api endpoint
        const response = await createUser(this.state);
        if (response.status === 200) {
            const respJson = await response.json();
            this.props.addUserLocal(
                this.state.firstName,
                this.state.lastName,
                respJson.uid
            );
            this.setState({
                firstName: "",
                lastName: "",
                email: "",
                password1: "",
                password2: ""
            })
            this.props.toggle();
        }

        // Handle local error
        else if (response.status === false) {
            this.setState({ errorMsg: response.message })
        }

        // Handle network error
        else {
            const contentType = response.headers.get("content-type");
            if (contentType==='application/json') {
                response.json().then(json => {
                    this.setState({ errorMsg: json.message })
                })
            } else {
                this.setState({ errorMsg: response.statusText })
            }
        }
        
        // Exit loading state
        this.setState({ loading: false });
    }

    render() {
        const { active, toggle } = this.props;
        return (
            <Modal isOpen={active} toggle={toggle}>
            <ModalHeader toggle={toggle}>New User</ModalHeader>
            <ModalBody>
                <Row form>
                    <Col md={6}>
                    <FormGroup>
                        <Label for="firstName">First Name</Label>
                        <Input 
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    </Col>
                    <Col md={6}>
                    <FormGroup>
                        <Label for="lastName">Last Name</Label>
                        <Input 
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input 
                        type="email"
                        name="email"
                        id="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="password1">Password</Label>
                    <Input 
                        type="password"
                        name="password1"
                        id="password1"
                        value={this.state.password1}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="password2">Confirm Password</Label>
                    <Input 
                        type="password"
                        name="password2"
                        id="password2"
                        value={this.state.password2}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <div className='text-center'><br/>
                    { this.state.loading ? (
                        <Spinner color='primary' />
                    ) : (
                        <Button
                        block
                        outline
                        color='primary'
                        onClick={this.handleSubmit}
                    >Create</Button>
                    )}
                    <br/>
                    { this.state.errorMsg !== "" ?
                        <Alert
                            color='danger'
                            toggle={() => this.setState({ errorMsg: "" })}
                        >
                            { this.state.errorMsg }
                        </Alert> : <div/>
                     }
                </div>
            </ModalBody>
        </Modal>
        )
    }
}

export default NewUser;