import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input, Spinner } from 'reactstrap';
import firebase from '../../firebase';

const NewLocation = (props) => {

    const [location, setLocation] = useState("");
    const [loading, setLoading] = useState(false);

    function closeModal() {
        props.toggle();
        setLocation("");
    }

    async function addLocation() {
        if (location === "") return;
        try {
            setLoading(true);

            // Add location to database
            const user = firebase.auth().currentUser;
            const token = await user.getIdTokenResult();
            const organization = token.claims.organization;
            const newLocationRef = await firebase.firestore()
                .collection('locations')
                .add({
                    name: location,
                    organization: organization,
                    services: [],
                    created: new Date()
                })

            // Update local state
            props.locations.push({
                name: location,
                id: newLocationRef.id,
                services: []
            })
            closeModal();
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
        <Modal isOpen={props.active} toggle={closeModal}>
            <ModalHeader toggle={closeModal}>New Location</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="location">Name</Label>
                    <Input 
                        type="text"
                        name="location"
                        id="location"
                        placeholder="ex. St Francis Center"
                        value={location}
                        onChange={e => setLocation(e.target.value)}
                    />
                </FormGroup>
                <div className='text-center' style={{paddingTop: 10}}>
                { loading ? <Spinner color='primary' /> :
                    <Button
                        outline
                        color='primary'
                        style={{width: '70%'}}
                        onClick={addLocation}
                    >Create</Button>
                }
                </div>
            </ModalBody>
        </Modal>
        </div>
    )
}

export default NewLocation;