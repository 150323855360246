import React, { useState, useContext } from 'react';
import { Container, TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import Navigation from '../user/Navigation';
import { badAuthStatus } from '../../helpers/AuthUtils';
import { AuthContext } from '../../context/AuthContext';
import ManageUsers from './ManageUsers';
import Locations from './Locations';

const Settings = () => {
    const [ tab, setTab ] = useState('1');
    const authStatus = useContext(AuthContext);

    // Check auth status
    const badJSX = badAuthStatus(authStatus);
    if (badJSX) return badJSX;

    return (
    <div>
        <Navigation active="settings"/><br/>
        <Container>
        <div>
            <Nav tabs>
                <NavItem>
                <NavLink
                    active={tab === '1'}
                    onClick={() => setTab('1')}
                >
                    Users
                </NavLink>
                </NavItem>

                <NavItem>
                <NavLink
                    active={tab === '2'}
                    onClick={() => setTab('2')}
                >
                    Locations
                </NavLink>
                </NavItem>
            </Nav>
            <br/>
        </div>

        <TabContent activeTab={tab}>
            <ManageUsers
                tabId='1'
            /> 
        </TabContent>

        <TabContent activeTab={tab}>
            <Locations
                tabId='2'
            /> 
        </TabContent>

        </Container>
    </div>
    )
}

export default Settings;