import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, FormGroup, 
    Label, Input, TabPane, Alert } from 'reactstrap';

const BulkUpdate = ({ services, dates, tabId, submit }) => {
    const [ lineCount, setlineCount ] = useState({});
    const [ textContent, setTextContent ] = useState({});
    const [ errorMsg, setErrorMsg ] = useState("");
    const [ successMsg, setSuccessMsg ] = useState("");
    const colWidth = (12 / (services ? services.length : 1));
    const expectedLines = dates.length;

    useEffect(() => {
        const initLines = {};
        const initText = {};
        for (let service of services) {
            initLines[service] = 1;
            initText[service] = "";
        }
        setlineCount(initLines);
        setTextContent(initText);
        setErrorMsg("");
        setSuccessMsg("");
    }, [dates])

    const handleChange = (e) => {
        const numLines = e.target.value.split(/\r\n|\r|\n/).length;
        const service = e.target.parentElement.parentElement.parentElement.id;
        setlineCount({...lineCount, [service]: numLines});
        setTextContent({...textContent, [service]: e.target.value});
    }

    const handleSubmit = (e) => {
        const service = e.target.parentElement.parentElement.parentElement.id;

        // Verify line count
        if (lineCount[service] !== expectedLines) {
            setErrorMsg("Invalid number of lines.")
            return;
        }

        // Verify numerical values
        const rows = textContent[service].split(/\r?\n/);
        for (let i=0; i<rows.length; i++) {
            if (isNaN(rows[i])) {
                setSuccessMsg("");
                setErrorMsg("Invalid value: " + rows[i]);
                return;
            } else {
                rows[i] = rows[i].replace(/\s/g,'');
            }
        }

        submit(service, rows);
        setErrorMsg("");
        setSuccessMsg("Local values updated for " + service + 
            ". Please verify on Table to submit to database.")
    }

    return (
        <TabPane tabId={tabId}><br/>
        <Alert 
            color='success'
            isOpen={ successMsg !== "" }
            toggle={() => setSuccessMsg("")}
        >{ successMsg }</Alert>
        <Alert 
            color='danger'
            isOpen={ errorMsg !== "" }
            toggle={() => setErrorMsg("")}
        >{ errorMsg }</Alert>
        <Row>
        {services && services.map(service => 
        <Col width={colWidth} key={service} id={service}>
            <Form>
                <FormGroup>
                    <Label><strong>{service}</strong></Label>
                    <Input
                        type='textarea'
                        rows='5'
                        id={service}
                        onChange={handleChange}
                        value={textContent[service]}
                    />
                    <span className='text-right'><p>{ lineCount[service]}/{ expectedLines } Lines</p></span>
                    <Button
                        onClick={handleSubmit}
                        outline
                        block
                        color='primary'
                    >Commit Local</Button>
                </FormGroup>
            </Form>
        </Col>    
        )}
        </Row>
        </TabPane>
    )
}

export default BulkUpdate;