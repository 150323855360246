import React, { Component } from 'react'
import { Container, Input, Table, Button, Alert, Col,
 Nav, NavItem, NavLink, TabPane, TabContent, Spinner } from 'reactstrap'
import Navigation from './Navigation'
import { AuthContext } from '../../context/AuthContext'
import { getRecordsForDateRange, recordsToState, setRecords } from '../../helpers/StoreUtils'
import { getDateStringRange } from '../../helpers/DateUtils';
import DatePicker from './DatePicker';
import BulkUpdate from './BulkUpdate'
import { Redirect } from 'react-router-dom'

class Records extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateRange: [],
            services: [],
            records: {},
            location: null,
            updated: null,
            tab: '1',
            loading: false
        };
        this.loadData = this.loadData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBulkUpdate = this.handleBulkUpdate.bind(this);
    }

    static contextType = AuthContext;

    async loadData({start, end, location}) {
        // Set services and date range
        console.log(location)
        this.setState({
            services: location.data.services,
            dateRange: getDateStringRange(start, end),
            location: location
        })

        // Get records for date range and add to state
        const records = await getRecordsForDateRange(start, end, location.id);
        this.setState({ records: 
            recordsToState(this.state.dateRange, this.state.services, records) 
        });
    }

    handleChange = (e, service) => {
        // Update records with new value
        const date = e.target.parentElement.parentElement.id;
        const records = this.state.records;
        records[date][service] = e.target.value;
        this.setState({ records });
    }

    handleBulkUpdate = (service, rows) => {
        let newRecords = this.state.records;
        let iter = 0;
        for (let date of this.state.dateRange) {
            newRecords[date][service] = rows[iter];
            iter++;
        }
    }

    closeAlert = () => {
        this.setState({ updated: null })
    }

    async handleSubmit() {
        this.setState({ loading: true });
        const success = await setRecords(this.state.records, this.state.location);
        this.setState({
            updated: success,
            loading: false
        });
    }

    render() {
        // Avoid rendering until auth state is retrieved
        if (!this.context.valid) return <div/>

        // Redirect to records page if logged in
        else if (this.context.valid && !this.context.loggedIn) return <Redirect to='/' />

        return (
        <div>
            <Navigation active='records'/><br/>
            <Container>
                <DatePicker submit={this.loadData}/><br/>

                <div>
                    <Nav tabs>
                        <NavItem>
                        <NavLink
                            active={this.state.tab === '1'}
                            onClick={() => this.setState({ tab: '1' })}
                        >
                            Table
                        </NavLink>
                        </NavItem>

                        <NavItem>
                        <NavLink
                            active={this.state.tab === '2'}
                            onClick={() => this.setState({ tab: '2' })}
                        >
                            Bulk Upload
                        </NavLink>
                        </NavItem>
                    </Nav>
                </div>

                <TabContent activeTab={this.state.tab}>

                <TabPane tabId='1'>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Date</th>
                            {this.state.services.map(service =>
                            // Create column for each service
                                <th key={'header'+service}>{service}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.dateRange.map(date =>
                    // Create row for each date
                    <tr key={date} id={date}>
                        <td>{date}</td>
                        {this.state.services.map(service => 
                        // Create table entry for each service
                        <td>
                        <Input
                            type='number'
                            key={date+service}
                            onChange={e => this.handleChange(e, service)}
                            value={date in this.state.records ? 
                                this.state.records[date][service] : ""
                            }
                        />
                        </td> // end service entry
                        )}
                    </tr> // end date row
                    )}
                    </tbody>
                </Table>

                {this.state.dateRange.length ? (
                    <div className='text-center'>

                    {this.state.updated !== null ? (
                    <Col md={{size: 6, offset: 3}}>
                        <Alert 
                            color='success'
                            isOpen={ this.state.updated }
                            toggle={() => this.setState({ updated: null })}
                        >
                            Records were updated.
                        </Alert>

                        <Alert
                            color='danger'
                            style={{ width: '50%' }}
                            isOpen={ !this.state.updated }
                            toggle={() => this.setState({ updated: null })}
                        >
                            Records could not be updated.
                        </Alert>
                        <br/>
                     </Col>
                     ) : <div/>}

                    { this.state.loading ? <Spinner color='primary' /> :
                        <Button
                            outline
                            style={{width: '50%'}}
                            color='primary'
                            onClick={this.handleSubmit}
                        >Submit</Button>
                    }
                    <br/>
                    
                    </div>) : <div/>
                }
                </TabPane>

                <BulkUpdate
                    tabId='2'
                    services={this.state.services}
                    dates={this.state.dateRange}
                    submit={this.handleBulkUpdate}
                />

                </TabContent>
            </Container><br/>
        </div>
        )
    }
}

export default Records;