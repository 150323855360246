import moment from 'moment'

export function getDateRange(start, end) {
    // Return array with single date if start=end
    if (dateToString(start) === dateToString(end)) {
        return [ start ];
    }

    // Iterate through dates in range and return array
    let dateRange = [];
    let d2 = moment.utc(end);
    for (let d1 = moment.utc(start); d1.diff(d2, 'days') <= 0; d1.add(1, 'days')) {
        dateRange.push(d1.toDate());
      }
    return dateRange;
}

export function getDateStringRange(start, end) {
    const dateRange = getDateRange(start, end);
    return dateRange.map(date => dateToString(date));
}

export function dateToString(date) {
    return moment.utc(date).format('ddd MMMM D, YYYY');
}

export function stringToDate(date) {
    return moment.utc(date, 'ddd MMMM D, YYYY').toDate();
}

export function dateToUTC(date) {
    return new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0, 0, 0, 0
    ));
}

export function firebaseFromToday(firebaseTime) {
    let predictDate = dateToUTC(firebaseTime.toDate());
    let predict = dateToString(predictDate)
    let todayDate = dateToUTC(new Date());
    let today = dateToString(todayDate);
    return predict === today;
}