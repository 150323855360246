import React from 'react';
import { Modal, ModalHeader, ModalBody, Button,  Row, Col } from 'reactstrap';

const NewLocation = (props) => {

    const { name, rmFunction, active, toggle } = props;

    function triggerDelete() {
        rmFunction();
        toggle();
    }

    return (
        <Modal isOpen={active} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
            <ModalBody>
                <p>Are you sure you want to delete {name}?</p>
                <Row className='text-center'>
                    <Col md='6'>
                        <Button
                            color='danger'
                            outline
                            block
                            onClick={triggerDelete}
                        >Remove</Button>
                    </Col>
                    <Col md='6'>
                        <Button
                            outline
                            block
                            onClick={toggle}
                        >Cancel</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default NewLocation;