import React, { Component } from 'react'
import DatePicker from '../../user/DatePicker';
import { badAuthStatus } from '../../../helpers/AuthUtils'
import { AuthContext } from '../../../context/AuthContext'
import { getDateStringRange } from '../../../helpers/DateUtils';
import { getRecordsForDateRange, recordsToState } from '../../../helpers/StoreUtils'
import { basicServiceStats } from '../../../helpers/StatUtils';
import Navigation from '../../user/Navigation';
import { Container, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import StatTable from './StatTable';
import LineGraph from './LineGraph';

class LocalStats extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            dateRange: [],
            services: [],
            records: {},
            location: null,
            updated: null,
            stats: [],
            tab: '1'
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData({start, end, location}) {
        // Set services and date range
        this.setState({
            services: location.data.services,
            dateRange: getDateStringRange(start, end),
            location: location
        })

        // Get records for date range and add to state
        const records = await getRecordsForDateRange(start, end, location.id);
        const recordState = recordsToState(this.state.dateRange, this.state.services, records);
        this.setState({
            records: recordState,
            stats: basicServiceStats(recordState, this.state.services)
        });
    }

    render() {
        // Check auth status
        const badJSX = badAuthStatus(this.context);
        if (badJSX) return badJSX;

        return (
            <div>
                <Navigation active='stats'/><br/>
                <Container>
                    <DatePicker submit={this.loadData}/><br/>
                    <div>
                    <Nav tabs>
                        <NavItem>
                        <NavLink
                            active={this.state.tab === '1'}
                            onClick={() => this.setState({ tab: '1' })}
                        >
                            Table
                        </NavLink>
                        </NavItem>

                        <NavItem>
                        <NavLink
                            active={this.state.tab === '2'}
                            onClick={() => this.setState({ tab: '2' })}
                        >
                            Attendance Chart
                        </NavLink>
                        </NavItem>
                    </Nav>
                    </div>
                    <TabContent activeTab={this.state.tab}>
                        <StatTable
                            records={this.state.records}
                            services={this.state.services}
                            stats={this.state.stats}
                            tabId="1"
                        />
                        <LineGraph
                            records={this.state.records}
                            services={this.state.services}
                            tabId="2"
                        />
                    </TabContent>
                </Container>
            </div>
        )
    }
}

export default LocalStats;