import { stringToDate } from './DateUtils';

export function basicStats(records, service) {
    let max = Number.MIN_VALUE;
    let min = Number.MAX_VALUE;
    let total = 0;
    let validRecords = 0;

    for (let date in records) {
        if (!(service in records[date]) ||
            isNaN(parseInt(records[date][service]))) 
            { continue; }
        validRecords++;
        const val = records[date][service];
        total += val;
        if (val > max) {
            max = val;
        }
        if (val < min) {
            min = val;
        }
    }

    let avg = NaN;
    if (validRecords !== 0)
        avg = Math.round(total / validRecords);
    else {
        avg = "";
        total = "";
    }

    if (max === Number.MIN_VALUE) { max = ""; }
    if (min === Number.MAX_VALUE) { min = ""; }
    
    return {
        max: max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        min: min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        avg: avg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        valid: total !== 0,
        total: total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
}

export function basicServiceStats(records, services) {
    let stats = {}
    for (let service of services) {
        stats[service] = basicStats(records, service);
    }
    return stats;
}



export function statsToLineData(records, services) {

    if (Object.keys(records).length === 0) { return []; }

    // Flatten record object into graph data format
    let graphData = [];
    for (let date in records) {
        let obj = { name: date };
        let containsService = false;
        for (let service of services) {
            if (service in records[date] && records[date][service] !== "") {
                containsService = true;
                obj[service] = records[date][service];
            }
        }
        if (containsService) { graphData.push(obj); }
    }
    graphData.sort(function(d1,d2) {
        return stringToDate(d1.name) - stringToDate(d2.name);
    });
    return graphData;
}