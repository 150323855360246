import React, { createContext, useState, useEffect } from 'react'
import firebase from '../firebase'

export const AuthContext = createContext()

const AuthContextProvider = (props) => {
    const [ valid, setValid ] = useState(false);
    const [ loggedIn, setLoggedIn ] = useState(false);
    
    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            setLoggedIn(!!user)
            setValid(true);
        })
    }, [])

    return (
        <AuthContext.Provider value={{loggedIn, valid}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;