import React, { Component } from 'react';
import { Button, Table, TabPane, Spinner } from 'reactstrap';
import NewUser from './NewUser';
import { getUsers, removeUser } from '../../helpers/AdminUtils';
import ConfirmDelete from './ConfirmDelete';
import firebase from '../../firebase';

class ManageUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            currentUID: "",
            removeObj: null,
            loading: "",
            newUserModal: false
        };
        this.addUserLocal = this.addUserLocal.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    async componentDidMount() {
        const users = await getUsers();
        const currentUser = await firebase.auth().currentUser;
        const currentUID = currentUser.uid;
        this.setState({ users, currentUID });
    }

    addUserLocal = (firstName, lastName, id) => {
        let usersCopy = this.state.users;
        let inserted = false;
        for (let i = 0; i < this.state.users.length; i++) {
            const stateCompare = this.state.users[i].firstName +
                                 this.state.users[i].lastName;
            if (firstName+lastName < stateCompare) {
                usersCopy.splice(i, 0, {
                    id: id,
                    firstName: firstName,
                    lastName: lastName
                });
                inserted = true;
                break;
            }
        }
        if (!inserted) {
            usersCopy.push({
                id: id,
                firstName: firstName,
                lastName: lastName
            })
        }
        this.setState({ users: usersCopy });
    }

    removeClickHandler = (user) => {
        this.setState({ removeObj: user })
    }

    removeUser = async () => {
        const uid = this.state.removeObj.id;
        this.setState({ loading: uid })
        const response = await removeUser(uid);
        if (response.status === 200) {
            this.setState({
                users: this.state.users.filter(user => user.id !== uid)
            })
        }
        this.setState({ loading: "" })
    }

    toggleNewUserModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                newUserModal: !prevState.newUserModal
            }
        })
    }

    render() {
        return (
            <TabPane tabId={this.props.tabId}>
                <div className='text-right'>
                    <Button
                        size='sm'
                        color='success'
                        outline
                        style={{marginBottom: 10}}
                        onClick={this.toggleNewUserModal}
                    >New User</Button>
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                    { this.state.users.map(user => 
                        <tr key={user.id} id={user.id}>
                            <td>{user.firstName} {user.lastName}</td>
                            <td>
                            <div className='text-center'>
                            { this.state.loading === user.id ?
                                <Spinner color='danger' /> :
                                <Button
                                    color='danger'
                                    size='sm'
                                    outline
                                    disabled={
                                        (user.id === this.state.currentUID) ||
                                        this.state.loading !== ""
                                    }
                                    onClick={() => this.removeClickHandler(user)}
                                >Remove User</Button>
                            }
                            </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                <NewUser
                    active={this.state.newUserModal}
                    toggle={this.toggleNewUserModal}
                    addUserLocal={this.addUserLocal}
                />
                <ConfirmDelete
                    active={this.state.removeObj}
                    name={this.state.removeObj ? 
                        this.state.removeObj.firstName + " " + this.state.removeObj.lastName
                        : ""
                    }
                    rmFunction={this.removeUser}
                    toggle={() => this.setState({removeObj: null})}
                />
            </TabPane>
        )
    }    
}

export default ManageUsers;