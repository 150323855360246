import firebase from '../firebase';
import moment from 'moment'
import { dateToString, stringToDate } from './DateUtils';

export async function getRecordsForDateRange(start, end, location) {
    try {
        // Get user's organization
        const user = firebase.auth().currentUser;
        const token = await user.getIdTokenResult();
        const organization = token.claims.organization;

        const records = await firebase.firestore().collection('records')
            .where('organization', '==', organization)
            .where('location', '==', location)
            .where('date', '>=', start)
            .where('date', '<=', end)
            .orderBy('date', 'asc')
            .get();
        return records;
    } catch (e) {
        console.log("GET ERROR")
        console.log(e)
        return null;
    }
}

export async function getPredictionsForDateRange(start, end, location) {
    try {
        // Get user organization
        let predictions = null;
        const user = firebase.auth().currentUser;
        const token = await user.getIdTokenResult();
        const organization = token.claims.organization;

        // Date range provided
        if (start && end) {
            predictions = await firebase.firestore().collection('predictions')
                .where('organization', '==', organization)
                .where('location', '==', location)
                .where('date', '>=', start)
                .where('date', '<=', end)
                .orderBy('date', 'asc')
                .get();
        }
        
        // No date range, start from today
        else {
            // Get timestamp for today midnight UTC
            let todayLocal = new Date();
            let todayUTC = new Date(Date.UTC(
                todayLocal.getFullYear(),
                todayLocal.getMonth(),
                todayLocal.getDate(),
                0, 0, 0, 0
            ));
            predictions = await firebase.firestore().collection('predictions')
                .where('organization', '==', organization)
                .where('location', '==', location)
                .where('date', '>=', todayUTC)
                .orderBy('date', 'asc')
                .get();
        }
        
        // Format predictions
        let formatted = [];
        predictions.forEach(pred => {
            let doc = pred.data();
            doc['date'] = dateToString(doc['date'].toDate());
            formatted.push(doc);
        })
        return formatted;
    } catch (e) {
        console.log(e)
        return null;
    }
}

export async function setRecords(records, location) {
    const services = location.data.services;
    try {
        const batch = firebase.firestore().batch();
        for (let key in records) {
            // Check if any fields were updated
            let record = records[key];
            let doc = getModifiedServices(record, services);
            if (Object.keys(doc).length === 0) {
                if ('docId' in record) {
                    const ref = firebase.firestore()
                        .collection('records')
                        .doc(record['docId']);
                    batch.delete(ref);
                }
                continue;
            }

            // Add info to doc
            doc['lastEdit'] = new Date();
            doc['location'] = location.id;
            doc['organization'] = location.data.organization;
            doc['date'] = stringToDate(key);

            // Update doc if it already exists
            if ('docId' in record) {
                const ref = firebase.firestore()
                    .collection('records')
                    .doc(record['docId']);
                batch.set(ref, doc);
            }

            // Otherwise create new doc
            else {
                const ref = firebase.firestore()
                    .collection('records')
                    .doc();
                batch.set(ref, doc)
            }
        }

        await batch.commit()
        return true;
    } catch(e) {
        console.log(e);
        return false;
    }
}

function getModifiedServices(record, services) {
    let serviceVals = {}
    for (let service of services) {
        if (service in record && record[service] !== "") {
            serviceVals[service] = parseInt(record[service]);
        }
    }
    return serviceVals;
}

export async function getLocations() {
    try {
        // Get user's organization
        const user = firebase.auth().currentUser;
        const token = await user.getIdTokenResult();
        const organization = token.claims.organization;

        // Get locations that belong to organization
        const locations = await firebase.firestore()
            .collection('locations')
            .where('organization', '==', organization)
            .get();
        
        // Format location data
        let data = []
        locations.forEach(location => {
            const doc = {
                id: location.id,
                data: location.data()
            }

            // Push to front if default location
            if (doc.data.default) {
                data.unshift(doc);
            } else {
                data.push(doc);
            }
        })

        return data;
    } catch (e) {
        console.log(e)
        return [];
    }
}

export function defaultLocation(locations) {
    for (let location of locations) {
        if (location.data.default) {
            return location;
        }
    }
    return null;
}

export function recordsToState(dateRange, services, records) {
    // Add existing records to state
    let state = {};
    records.forEach(record => {
        const data = record.data()
        const date = dateToString(data.date.toDate())
        state[date] = { docId: record.id };
        for (let service of services) {
            if (service in data) {
                state[date][service] = data[service];
            }
        }
    })
    
    // Add blank entry for dates without a record
    for (let date of dateRange) {
        if (!(date in state)) {
            state[date] = {};
            for (let service of services) {
                state[date][service] = "";
            }
        }
    }

    return state;
}

export function recordsToBulk(records, dates, services) {
    
    if (Object.keys(records).length === 0) { return; }
    
    let bulk = {};
    for (let service of services) {
        let rows = "";
        for (let date of dates) {
            if (service in records[date]) {
                rows += records[date][service] + String.fromCharCode(13, 10);
            } else {
                rows += String.fromCharCode(13, 10);
            }
        }
        bulk[service] = rows.slice(0, -2);
    }
    return bulk;
}

function recordsToExport(records, services) {
    let exports = [];
    records.forEach(record => {
        let fireDoc = record.data();
        let exportDoc = {};
        exportDoc['date'] = moment.utc(fireDoc['date'].toDate()).format('YYYY-MM-DD');
        for (let service of services) {
            if (service in fireDoc) {
                exportDoc[service] = fireDoc[service];
            }
        }
        exports.push(exportDoc);
    })
    return exports;
}

export async function getRecordsForExport(location, services) {
    try {
        const records = await firebase.firestore().collection('records')
            .where('location', '==', location)
            .orderBy('date', 'asc')
            .get();
        const formatted = recordsToExport(records, services);
        await firebase.firestore().collection('locations')
            .doc(location)
            .update({ exported: new Date() });
        return formatted;

    } catch (e) {
        console.log("GET ERROR")
        console.log(e)
        return [];
    }
}