import React, { useState, useEffect } from 'react';
import { TabPane, Col, Row, ListGroup, ListGroupItem } from 'reactstrap';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
  } from 'recharts';
import { statsToLineData } from '../../../helpers/StatUtils';

const LineGraph = ({ records, services, tabId }) => {
    
    const data = statsToLineData(records, services);
    const colors = ['#16697A', '#FFA62B', '#489FB5', '#82C0CC', '#66A182'];
    const [ selectedService, setSelectedService ] = useState("");
    const [ filteredRecords, setFilteredRecords ] = useState([]);
    
    // Select first service on services change
    useEffect(() => {
        setSelectedService(services.length > 0 ? services[0] : "")
    }, [services])

    // Filter data on selected service change
    useEffect(() => {
        if (data && selectedService !== "") {
            let filtered = [];
            for (let d of data) {
                if (selectedService in d) {
                    filtered.push(d)
                }
            }
            setFilteredRecords(filtered);
        }
    }, [records, selectedService])

    // Handle uninitialized records
    if (Object.keys(records).length === 0) { return <div/> }

    const serviceSelector = (
        <Col md={2}>
            <ListGroup>
            { services && services.map(service => 
                <ListGroupItem
                    active={selectedService === service}
                    onClick={() => setSelectedService(service)}
                >{service}</ListGroupItem>
            )}
            </ListGroup>
        </Col>
    );

    return (
        <TabPane tabId={tabId}><br/>
        <Row>
            <Col md={10}>
            <ResponsiveContainer width="99%" aspect={2.5}>
            <LineChart
                data={filteredRecords}
                margin={{
                top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                    type="monotone"
                    stroke={colors[Math.floor(Math.random() * colors.length)]}
                    dataKey={selectedService}
                />
            </LineChart>
            </ResponsiveContainer>
            </Col>

            { services.length > 1 ? serviceSelector : <div/> }

        </Row>
        </TabPane>
    )
}

export default LineGraph;