import React from 'react';
import { Table } from 'reactstrap';

const PredictionTable = (props) => {
    const { services, predictions } = props;
    return (
        <Table striped>
            <thead>
                <th>Date</th>
                { services && services.map(service => 
                    <th>{ service }</th>
                )}
            </thead>
            <tbody>
                { predictions && predictions.map(prediction => 
                    <tr>
                        <td>{ prediction.date }</td>
                        { services && services.map(service => 
                            <td>{ service in prediction ? prediction[service] : ""}</td>
                        )}
                    </tr>
                )}
            </tbody>
        </Table>
    )
}

export default PredictionTable;