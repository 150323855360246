import firebase from '../firebase'
const api = 'https://orchard-learn.herokuapp.com';
//const api = 'http://127.0.0.1:5000/';

export const createUser = async ( form ) => {

    // Verify fields exist
    const fields = ['firstName', 'lastName', 'email', 'password1', 'password2'];
    for (let field of fields) {
        if (form[field] === "") {
            return {
                status: false,
                message: "Please complete all fields."
            }
        }
    }

    // Validate passwords match
    const {  password1, password2 } = form;
    if (password1 !== password2) {
        return {
            status: false,
            message: "Passwords do not match."
        }
    }

    // Validate password length
    if (password1.length < 8) {
        return {
            status: false,
            message: "Password must be at least 8 characters."
        }
    }

    // Send credentials to Flask backend
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await fetch(api + '/new_user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: token,
            email: form.email,
            password: form.password1,
            firstName: form.firstName,
            lastName: form.lastName
        })
    });
    return response;
}

export const removeUser = async ( uid ) => {
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await fetch(api + '/remove_user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: token,
            uid: uid
        })
    });
    return response;;
}

export async function getUsers() {
    try {
        const token = await firebase.auth().currentUser.getIdTokenResult();
        const organization = token.claims.organization;
        const userSnapshot = await firebase.firestore()
            .collection('users')
            .where('organization', '==', organization)
            .orderBy('firstName')
            .orderBy('lastName')
            .get()
        let users = [];
        userSnapshot.forEach(user => {
            let userDict = {};
            userDict['id'] = user.id;
            userDict['firstName'] = user.data().firstName;
            userDict['lastName'] = user.data().lastName;
            users.push(userDict);
        })
        return users;
    } catch (e) {
        console.log(e.message)
        return []
    }
}

export async function triggerPredict(location) {
    const token = await firebase.auth().currentUser.getIdToken();
    const url = `${api}/predict?token=${token}&location=${location}`;
    await fetch(url);
}