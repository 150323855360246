import React, { useState, useContext } from 'react'
import firebase from '../../firebase';
import { GiFruitBowl } from 'react-icons/gi'
import { AuthContext } from '../../context/AuthContext'
import { Redirect } from 'react-router-dom'
import { Col, Container, Form, FormGroup, Input,
    Button, Alert } from 'reactstrap'


const Login = (props) => {
    // Set hooks and variables for render
    const { valid, loggedIn } = useContext(AuthContext);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loginError, setLoginError ] = useState("");
    const [ resetMsg, setResetMsg ] = useState("");
    const iconSize = document.documentElement.clientHeight * 0.4;

    // Callback when user submits login info
    const firebaseLogin = e => {
        e.preventDefault()
        firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
            props.history.replace('/records')
        }).catch(() => {
            setLoginError("Error: Please check email and password.");
        })
    }

    // Forgot password callback
    const forgotPassword = () => {
        // Check that email is valid
        const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const validEmail = regex.test(String(email).toLowerCase());
        if (!validEmail) {
            setLoginError("Error: Please enter a valid email.")
            return;
        }

        // Send reset email
        firebase.auth().sendPasswordResetEmail(email).then(() => {
            setResetMsg("Password reset email was sent.")
        }).catch(() => {
            setResetMsg("Password reset email was sent.")
        })
    }

    // Avoid rendering until auth state is retrieved
    if (!valid) return <div/>

    // Redirect to records page if logged in
    else if (valid && loggedIn) return <Redirect to='/records' />

    return (
        <Container className='text-center' style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: '100vh'
        }}>
        <Col sm='12' md='4'>
            <GiFruitBowl size={ iconSize } />
            <Alert
                color='danger'
                isOpen={ loginError !== "" }
                toggle={() => setLoginError("")}
            >{ loginError }</Alert>
            <Alert
                color='success'
                isOpen={ resetMsg !== "" }
                toggle={() => setResetMsg("")}
            >{ resetMsg }</Alert>
            <Form style={{padding: '3vh'}}>
                <FormGroup>
                    <Input type="email" name="email" id="email" placeholder="Email"
                        value={email} onChange={e => setEmail(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Input type="password" name="password" id="password" placeholder="Password"
                        value={password} onChange={e => setPassword(e.target.value)} />
                </FormGroup>
                <Button
                    outline
                    color='primary'
                    onClick={firebaseLogin}
                    style={{ width: '50%' }}
                >Log In</Button>
                
            </Form>
            <Button
                    color='link'
                    onClick={ forgotPassword }
                >Forgot Password?</Button>
        </Col>
        </Container>
    )
}

export default Login;