import React, { useState } from 'react'
import firebase from '../../firebase'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

const Navigation = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const logOut = () => {
    firebase.auth().signOut();
  }

  const { active } = props;

  return (
    <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">Orchard</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink active={active==="records"} href="/records">Records</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={active==="stats"} href="/stats">Stats</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={active==="predict"} href="/predict">Predict</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={active==="map"} href="/map">Map</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={active==="settings"} href="/settings">Settings</NavLink>
            </NavItem>
            <NavItem onClick={() => logOut()}>
              <NavLink href='#'>Log Out</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Navigation;