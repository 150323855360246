import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-functions'
import 'firebase/firebase-firestore'

const firebaseConfig = {
    apiKey: "AIzaSyA1I2s3iTCjXQoyCw1LLEj2Rb4w0N4YyLk",
    authDomain: "orchard-3ed8c.firebaseapp.com",
    databaseURL: "https://orchard-3ed8c.firebaseio.com",
    projectId: "orchard-3ed8c",
    storageBucket: "orchard-3ed8c.appspot.com",
    messagingSenderId: "58090843437",
    appId: "1:58090843437:web:797413c9c32d6daf99b7a4"
};

firebase.initializeApp(firebaseConfig);
export default firebase;