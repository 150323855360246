import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, CustomInput } from 'reactstrap';
import MapDetail from './MapDetail';
import Navigation from '../user/Navigation';

export default class MapContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radius: 25,
            layers: []
        };
    }

    updateLayers(layer) {
        if (this.state.layers.includes(layer)) {
            const filtered = this.state.layers.filter(e => e !== layer);
            this.setState({ layers: filtered })
        } else {
            this.setState({ layers: [...this.state.layers, layer] })
        }
    }

    render() {
        return (
            <div>
                <Navigation active='map' />
                <br/>
                <Container>
                <Row>
                    <Col md={9}>
                        <MapDetail
                            radius={this.state.radius}
                            layers={this.state.layers}
                        />
                    </Col>
                    <Col md={3}>
                        <Card>
                        <CardBody>
                            <FormGroup>
                                <Label for='radius'>Radius</Label>
                                <CustomInput
                                    type='range'
                                    name='radius'
                                    min={0}
                                    max={50}
                                    step={5}
                                    value={this.state.radius}
                                    onChange={e => this.setState({ radius: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="layers">Layers</Label>
                                    <div>
                                        <CustomInput 
                                            type="switch"
                                            id="poverty"
                                            name="poverty"
                                            label='Below 200% Poverty Line'
                                            onClick={() => this.updateLayers('poverty')}
                                        />
                                        <CustomInput
                                            type="switch"
                                            id="covid"
                                            name="covid"
                                            label='COVID Case Rate'
                                            onClick={() => this.updateLayers('covid')}
                                        />
                                    </div>
                            </FormGroup>
                        </CardBody>
                        </Card>
                    </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}