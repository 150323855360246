import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { getLocations } from '../../helpers/StoreUtils';

class DatePicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            selectedLocation: "",
            startDate: null,
            endDate: null
        };
    }

    async componentDidMount() {
        const locations = await getLocations();
        let defaultLocation = null;
        for (let location of locations) {
            if (location.data.default) {
                defaultLocation = location;
                break;
            }
        }
        this.setState({
            locations: locations,
            selectedLocation: defaultLocation ? defaultLocation.data.name : locations[0].data.name
        })
    }

    handleSubmit = (e) => {
        // Verify dates (if dates were not disabled)
        const { selectedLocation, startDate, endDate } = this.state;
        const disableDates = this.props.disableDates ? true : false;
        if (!disableDates && (!startDate || !endDate) || 
            (new Date(startDate) > new Date(endDate)) )
            { return; }
        
        // Find location object for selected location
        let locationObj = null;
        for (let location of this.state.locations) {
            if (location.data.name === selectedLocation) {
                locationObj = location;
                break;
            }
        }

        // Pass selected information to the parent
        this.props.submit({
            start: new Date(startDate),
            end: new Date(endDate),
            location: locationObj
        })
    }

    render() {
        const { locations } = this.state;
        const disableDates = this.props.disableDates ? true : false;
        return (
            <Form>
            <Row form>
                <Col md={3}>
                <FormGroup>
                    <Label for="location">Location</Label>
                    <Input 
                        type="select"
                        name="location"
                        id="location"
                        onChange={e => this.setState({
                            selectedLocation: e.target.value
                        })}
                        >
                        {locations.map(location => 
                            <option>{location.data.name}</option>
                        )}
                    </Input>
                </FormGroup>
                </Col>

                <Col md={3}>
                <FormGroup>
                    <Label for="from">From</Label>
                    <Input
                        type="date"
                        name="from"
                        id="from"
                        disabled={disableDates}
                        onChange={e => this.setState({
                            startDate: e.target.value
                        })}
                    />
                </FormGroup>
                </Col>

                <Col md={3}>
                <FormGroup>
                    <Label for="to">To</Label>
                    <Input
                        type="date"
                        name="to"
                        id="to"
                        disabled={disableDates}
                        onChange={e => this.setState({
                            endDate: e.target.value
                        })}
                    />
                </FormGroup>
                </Col>
                
                <Col md={3} className="d-flex align-items-end">
                <FormGroup >
                    <Button 
                    outline
                    color='primary'
                    onClick={this.handleSubmit}
                    >Select</Button>
                </FormGroup>
                </Col>
            </Row>
            </Form>
        )}
}

export default DatePicker;