import React from 'react';
import { Table, TabPane } from 'reactstrap';

const StatTable = ({ stats, services, tabId }) => {

    // Handle uninitialized records
    if (Object.keys(stats).length === 0) { return <div/> }

    // Render stats on table
    return (
    <TabPane tabId={tabId}>
        <Table striped>
            <thead>
                <tr>
                    <th/>
                    { services && services.map(service => 
                        <th>{service}</th>    
                    )}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Avg Attendance</th>
                    { services && services.map(service => {
                        if (service in stats) return (
                            <td>{!isNaN(stats[service].avg)
                                && stats[service].avg !== 0 ? 
                                stats[service].avg : ""
                            }</td>
                        ); else return <td/>;
                    })}
                </tr>
                <tr>
                    <th>Min Attendance</th>
                    { services && services.map(service => {
                        if (service in stats) return (
                            <td>{stats[service].min !== Number.MAX_VALUE ? 
                                stats[service].min : ""
                            }</td>
                        ); else return <td/>;
                    })}
                </tr>
                <tr>
                    <th>Max Attendance</th>
                    { services && services.map(service => {
                        if (service in stats) return (
                            <td>{stats[service].max !== Number.MIN_VALUE ? 
                                stats[service].max : ""
                            }</td>
                        ); else return <td/>;
                    })}
                </tr>
                <tr>
                    <th>Total Attendance</th>
                    { services && services.map(service => {
                        if (service in stats) return (
                            <td>{stats[service].total !== 0 ? 
                                stats[service].total : ""
                            }</td>
                        ); else return <td/>;
                    })}
                </tr>
            </tbody>
        </Table> 
    </TabPane>
    )
}

export default StatTable;