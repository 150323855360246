import React, { Component } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { getPredictionsForDateRange } from '../../helpers/StoreUtils';
import Navigation from '../user/Navigation';
import { Nav, NavItem, NavLink, Container, TabPane, TabContent } from 'reactstrap';
import DatePicker from '../user/DatePicker';
import { Redirect } from 'react-router-dom'
import CurrentPredictions from './CurrentPredictions';
import PredictionTable from './PredictionTable';

class Predict extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            tab: '1',
            predictions: [],
            location: "",
            organization: "",
            start: new Date(),
            end: new Date()
        }
        this.loadData = this.loadData.bind(this);
        this.loadDataOnly = this.loadDataOnly.bind(this);
    }

    async loadData({start, end, location}) {
        console.log (start, end, location)
        this.setState({ start, end, location });
        let predictions = []
        if (this.state.tab === '1') {
            predictions = await getPredictionsForDateRange(null, null, location.id);
        } else {
            predictions = await getPredictionsForDateRange(start, end, location.id);
        }
        this.setState({ predictions });
    }

    async loadDataOnly() {
        const predictions = await getPredictionsForDateRange(
            null,
            null,
            this.state.location.id
        );
        this.setState({ predictions });
    }

    render() {

        // Avoid rendering until auth state is retrieved
        if (!this.context.valid) return <div/>

        // Redirect to records page if logged in
        else if (this.context.valid && !this.context.loggedIn)
            return <Redirect to='/' />

        return (
            <div>
            <Navigation active='predict' />

            <Container>
                <br/>
                <DatePicker
                    disableDates={this.state.tab==='1'}
                    submit={this.loadData}
                />
                <br/>
                <Nav tabs>
                    <NavItem>
                    <NavLink
                        active={this.state.tab === '1'}
                        onClick={() => this.setState({ tab: '1' })}
                    >
                        Current
                    </NavLink>
                    </NavItem>

                    <NavItem>
                    <NavLink
                        active={this.state.tab === '2'}
                        onClick={() => this.setState({ tab: '2' })}
                    >
                        Archived
                    </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.tab}>
                    <CurrentPredictions
                        tabId='1'
                        active={this.state.location}
                        location={this.state.location}
                        refreshData={this.loadDataOnly}
                        services={this.state.location ? this.state.location.data.services : null}
                        predictions={this.state.predictions}
                    />
                </TabContent>

                <TabContent activeTab={this.state.tab}>
                    <TabPane tabId='2'>
                        <PredictionTable
                            services={this.state.location ? this.state.location.data.services : null}
                            predictions={this.state.predictions}
                        />
                        <br/>
                    </TabPane>
                </TabContent>

            </Container>
            </div>
        )
    }
}

export default Predict;